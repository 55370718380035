import { useNavigate } from "react-router-dom";
import Button from "../../components/widgets/Button";
import { ROUTES } from "../../constants/routes-constants";
import { ChurchAdminAccoundSettingEnum } from "../../models/church-admin.model";

const PaymentSuccessWithoutAuth = () => {
  const navigate = useNavigate();
  // Navigate to Dashboard
  const goToDashboard = () => {
    sessionStorage.removeItem("isPaymentInProcess");
    navigate(
      ROUTES.CHURCH_ADMIN_ACCOUNT(ChurchAdminAccoundSettingEnum.CHURCH_INFO),
      {
        replace: true,
      }
    );
  };

  const goToLoginPage = () => {
    navigate(ROUTES.LOGIN, { replace: true });
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center px-4">
      <div className="max-w-md w-full shadow-lg rounded-lg p-8 text-center">
        <div className="w-16 h-16 bg-green-500 rounded-full flex items-center justify-center mx-auto">
          <svg
            className="w-8 h-8 text-green-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="text-white"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            ></path>
          </svg>
        </div>
        <h2 className="mt-6 text-3xl font-extrabold">Payment Successful!</h2>
        <p className="mt-2 text-sm">
          Thank you for your payment. Your transaction has been completed
          successfully.
        </p>

        {/* Password Reset Info */}
        <div className="mt-6 text-left">
          <h3 className="text-lg font-semibold">
            You haven't completed the password reset process yet.
          </h3>
          <p className="mt-2 text-sm">
            Please follow these steps to reset your password:
          </p>
          <ul className="list-decimal list-inside mt-2 text-sm">
            <li>Check your email inbox for a password reset message.</li>
            <li>Open the email and click on the "Generate Password" button.</li>
            <li>
              Follow the instructions provided to create a new password for your
              account.
            </li>
          </ul>
          <p className="mt-4 text-sm">
            If you have already completed the "Generate Password" step, you can
            proceed to the login page by clicking the button below.
          </p>
        </div>

        <div className="mt-8 space-y-3">
          {/* <Button
            onClick={goToDashboard}
            className="w-full"
            label="Return to Dashboard"
          /> */}
          <Button
            onClick={goToLoginPage}
            className="w-full"
            label="Proceed to Login"
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessWithoutAuth;
