export interface ChurchInfoFormDataModel {
  churchName: string;
  email: string;
  phoneNumber: string;
  contactPersonName: string;
  physicalAddress: string;
  city: string;
  state: string;
  zipCode: string;
  requestPrayerLink: string;
}

export interface ChurchImagesFormDataModel {
  churchLogo: any;
  coverPhoto: any;
}

export interface NextStepLinksFormDataModel {
  baptismSignUpLink: string;
  communitySignUpLink: string;
  serveSignUpLink: string;
  link: string;
}

export interface PaymentInfoFormDataModel {
  cardNumber: string;
  expirationDate: string;
  clv: string;
  billingZipCode: string;
}

export enum ChurchAdminAccoundSettingEnum {
  CHURCH_INFO = 1,
  USER_PASS = 2,
  CHURCH_IMAGES = 3,
  NEXT_STEP_LINKS = 4,
  SUBSCRIPTION = 5,
  NOTIFICATION = 6,
  HISTORY_NOTIFICATION = 7,
}

export interface NotificationFormDataModel {
  title: string;
  notificationBody: string;
}

export interface DropdownOption {
  label: string;
  value: string | number;
}

export interface MapItems {
  data: any;
  labelKey: string;
  valueKey: string;
}

export interface SingleSelectDropdownProps {
  options: DropdownOption[];
  placeholder?: string;
  onChange: (selectedValue: string | number) => void;
  value?: string | number;
  disabled?: boolean;
  readonly?: boolean;
  validation?: boolean;
}

export interface HistoryOfNotifications {
  id?: string;
  subject: string;
  message: string;
  status: string;
  createdAt: string;
  userId: string;
}