import { ReactNode, useCallback, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ROUTES } from "../constants/routes-constants";
import { useUserContext } from "../context/UserProvider";
import { RoleEnum } from "../models/auth-form.model";
import NoAccessPage from "../components/no-access/NoAccessPage";
import { ChurchAdminAccoundSettingEnum } from "../models/church-admin.model";
import AppDialog from "../components/app-dialog/AppDialog";
import RestrictSmallDeviceDialog from "../components/restrict-small-device-dialog/RestrictSmallDeviceDialog";

const AuthRedirect = ({ children }: { children: ReactNode }) => {
  const { userData, setUserData } = useUserContext();
  const { pathname } = useLocation();
  const [openDialog, setOpenDialog] = useState(false);

  const logout = useCallback(() => {
    localStorage.clear();
    setUserData(null);
  }, [setUserData]);

  const LogoutDialog = useCallback(() => {
    return (
      <>
        <AppDialog
          isOpen={openDialog}
          title="Log Out Before Continuing."
          onConfirm={logout}
          onClose={() => {}}
          description={""}
          isLoading={false}
          hideCloseBtn={true}
          confirmTitle="Logout"
        />
      </>
    );
  }, [logout, openDialog]);

  useEffect(() => {
    if (userData && userData.token && pathname === ROUTES.SIGNUP) {
      setOpenDialog(true);
    }
  }, [pathname, userData]);

  return (
    <>
      <div className="lg:block hidden">
        {userData && userData.token ? (
          pathname === ROUTES.SIGNUP ? (
            <LogoutDialog />
          ) : userData?.userRoles?.includes(
              RoleEnum.PLATEFORM_ADMIN?.toString()
            ) ? (
            <Navigate to={ROUTES.PLATFORM_ADMIN_HOME} replace={true} />
          ) : userData?.userRoles?.includes(
              RoleEnum?.CHURCH_ADMIN?.toString()
            ) ? (
            <Navigate
              to={ROUTES.CHURCH_ADMIN_ACCOUNT(
                ChurchAdminAccoundSettingEnum.CHURCH_INFO
              )}
              replace={true}
            />
          ) : (
            <NoAccessPage />
          )
        ) : (
          <> {children}</>
        )}
      </div>
      <div className="block lg:hidden m-5">
        <RestrictSmallDeviceDialog />
      </div>
    </>
  );
};

export default AuthRedirect;
