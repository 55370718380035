import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen">
      <div className="max-w-3xl mx-auto px-4 py-12">
        <h1 className="text-3xl font-bold mb-6">
          Privacy Policy for Your First 30
        </h1>
        <p className="mb-8">Last Updated: 10/17/2024</p>

        {/* Introduction */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Introduction</h2>
          <p>
            Your First 30 Days LLC ("Company," "we," or "us") is committed to
            respecting your privacy. This Privacy Policy describes how we
            collect, use, and disclose your personal information ("Personal
            Information") when you access the website{" "}
            <span className="font-medium">yourfirstthirtydays.com</span>{" "}
            ("Site") or use the Your First Thirty Days app and related services
            (collectively, the "Services").
          </p>
        </section>

        {/* Section 1 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">
            1. Personal Information We Collect
          </h2>
          <ul className="list-disc ml-6">
            <li>
              <span className="font-medium">
                Personal Information You Provide:
              </span>{" "}
              When you create an account, communicate with us, or use the
              Services, we collect information like your name, email address,
              and any messages or queries you send to us.
            </li>
            <li>
              <span className="font-medium">
                Information Collected Automatically:
              </span>{" "}
              We may automatically collect:
              <ul className="list-disc ml-6 mt-2">
                <li>
                  <span className="font-medium">Log Data:</span> Information
                  such as IP addresses, browser types, and the times you
                  interact with the Site or Services.
                </li>
                <li>
                  <span className="font-medium">Device Information:</span> The
                  type of device you use, its operating system, and browser
                  type.
                </li>
              </ul>
            </li>
          </ul>
        </section>

        {/* Section 2 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">
            2. Cookies and Tracking Technologies
          </h2>
          <p>
            We use cookies and similar tracking technologies to enhance your
            experience and collect aggregated data about site usage. You can
            adjust your browser settings to manage or reject cookies, but
            disabling cookies may limit some features of the Services.
          </p>
        </section>

        {/* Section 3 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">
            3. How We Use Your Personal Information
          </h2>
          <ul className="list-disc ml-6">
            <li>To provide and maintain the Services.</li>
            <li>
              To communicate with you regarding your account or any support
              inquiries.
            </li>
            <li>To analyze and improve the functionality of the Services.</li>
            <li>To enhance security and prevent fraud.</li>
            <li>
              For research and analysis to better understand user behavior and
              preferences.
            </li>
          </ul>
        </section>

        {/* Section 4 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">
            4. Sharing of Personal Information
          </h2>
          <ul className="list-disc ml-6">
            <li>
              <span className="font-medium">Service Providers:</span> We may
              share your information with third-party vendors that help operate
              the Services (e.g., hosting, payment processing, and analytics).
            </li>
            <li>
              <span className="font-medium">Business Transfers:</span> If Your
              First 30 Days LLC is involved in a merger, acquisition, or asset
              sale, your information may be transferred as part of that
              transaction.
            </li>
            <li>
              <span className="font-medium">Legal Obligations:</span> We may
              disclose your information when required by law or to protect our
              rights and the safety of others.
            </li>
          </ul>
        </section>

        {/* Section 5 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">5. Children’s Privacy</h2>
          <p>
            The Services are not intended for children under the age of 13, and
            we do not knowingly collect Personal Information from children. If
            we learn that we have collected information from a child under 13,
            we will delete it promptly. If you believe that we have
            inadvertently collected such information, please contact us at{" "}
            <span className="font-medium">support@yourfirstthirtydays.com</span>
            .
          </p>
        </section>

        {/* Section 6 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">6. Data Security</h2>
          <p>
            We take reasonable measures to protect your Personal Information
            from unauthorized access or disclosure. However, no internet
            transmission is completely secure, and we cannot guarantee the
            absolute security of your data.
          </p>
        </section>

        {/* Section 7 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">
            7. International Data Transfers
          </h2>
          <p>
            By using the Services, you consent to the transfer of your
            information to the United States, where our servers are located.
          </p>
        </section>

        {/* Section 8 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">8. Your Choices</h2>
          <p>
            You may update or delete your account information and unsubscribe
            from communications by following instructions provided in our
            communications or contacting us at{" "}
            <span className="font-medium">support@yourfirstthirtydays.com</span>
            .
          </p>
        </section>

        {/* Section 9 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">
            9. Changes to This Privacy Policy
          </h2>
          <p>
            We may update this Privacy Policy periodically. Any changes will be
            posted on this page with the updated date. We encourage you to
            review this policy regularly.
          </p>
        </section>

        {/* Section 10 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">
            10. Account Deletion Policy
          </h2>
          <p>
            Users can delete their account directly from the app by navigating
            to their profile, selecting the "Delete Account" option, and
            confirming the action.
            <br /> For users who have uninstalled the app and wish to delete
            their account, they can visit{" "}
            <a
              href="https://yourfirstthirtydays.com/delete-user-info"
              className="text-blue-600 underline"
              target="_blank"
            >
              https://yourfirstthirtydays.com/delete-user-info
            </a>{" "}
            and follow the provided instructions to complete the account
            deletion process.
          </p>
        </section>

        {/* Section 11 */}
        <section>
          <h2 className="text-xl font-semibold mb-4">11. Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at{" "}
            <a
              href="mailto:support@yourfirstthirtydays.com."
              className="text-blue-600 underline"
            >
              support@yourfirstthirtydays.com
            </a>
            .
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
