import { HistoryOfNotifications } from "../../models/church-admin.model";

const NotificationItem: React.FC<HistoryOfNotifications> = ({
  id,
  userId,
  subject,
  message,
  createdAt,
  status,
}) => {
  return (
    <div className="border bottom-2 border-white p-4 rounded shadow-sm bg-[#FFFFFF0A] mt-2">
      <h3 className="text-lg font-bold">{subject}</h3>
      <p className="text-sm text-gray-300">{message}</p>
      <div className="text-xs text-gray-400 mt-2">
        <span>Status: {status}</span> |{" "}
        <span>{new Date(createdAt).toLocaleString()}</span>
      </div>
    </div>
  );
};

export default NotificationItem;
