import { Instagram } from "lucide-react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes-constants";

const Footer = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleEmailClick = () => {
    const to = "support@yourfirstthirtydays.com";
    const subject = "Contact YourFirst30Days ";
    const body = "";

    const mailtoLink = `mailto:${encodeURIComponent(
      to
    )}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    // Open email client
    window.location.href = mailtoLink;
  };
  const SocialMediaAccounts = () => {
    return (
      <div className="flex space-x-4">
        {/* <Link to={ROUTES.LANDING_PAGE} className="hover:text-gray-400">
          <span className="w-6 h-6 block  rounded-full">
            <img src="/assets/svg/facebook-icon.svg" alt="icon" />
          </span>
        </Link>
        <Link to={ROUTES.LANDING_PAGE} className=" hover:text-gray-400">
          <span className="w-6 h-6 block rounded-full">
            <img src="/assets/svg/x-icon.svg" alt="icon" />
          </span>
        </Link> */}
        <Link
          target="_blank"
          to={ROUTES.INSTAGRAM_PAGE}
          className=" hover:text-gray-400"
        >
          <span className="w-6 h-6 block rounded-full">
            <Instagram />
          </span>
        </Link>
      </div>
    );
  };

  const TermLink = () => {
    return (
      <Link
        className="text-sm hover:text-gray-400"
        to={ROUTES.TERMS_CONDITIONS}
      >
        Terms
      </Link>
    );
  };

  const PrivacyLink = () => {
    return (
      <Link className="text-sm hover:text-gray-400" to={ROUTES.PRIVACY_POLICY}>
        Privacy
      </Link>
    );
  };

  const ContactUs = () => {
    return (
      <label className="cursor-pointer hover:text-gray-400">
        Contact Us
        <div
          className="text-xs cursor-pointer"
          onClick={() => handleEmailClick()}
        >
          support@yourfirstthirtydays.com
        </div>
      </label>
    );
  };

  return (
    <footer className="py-8 bg-background">
      <div className="px-16 mb-4">
        {/* Mobile view */}
        <div className="container mx-auto px-4 md:hidden">
          <div className="flex flex-col items-center space-y-4">
            {/* Social Media Links */}
            <div className="">
              <SocialMediaAccounts />
              <ContactUs />
            </div>

            {/* Links */}
            <div className="flex items-center space-x-2 text-sm">
              <TermLink />
              <span>•</span>
              <PrivacyLink />
              <span>•</span>
            </div>

            {/* Copyright */}
            <div className="text-sm">© 2024 Your First 30 Days</div>
          </div>
        </div>

        {/* Desktop View */}
        <div className=" md:flex hidden md:flex-row justify-between items-center gap-4">
          <div className="flex space-x-4">
            <p className="text-sm">© 2024 Your First 30 Days</p>
            <TermLink />
            <PrivacyLink />
          </div>
          <div className="flex gap-6">
            <div className="flex gap-5 items-center">
              <SocialMediaAccounts />
              <ContactUs />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
