import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes-constants";

const DeleteAccountInfoPage: React.FC = () => {
  const TermLink = () => {
    return (
      <Link
        className="text-sm hover:text-gray-400"
        to={ROUTES.TERMS_CONDITIONS}
      >
        Terms
      </Link>
    );
  };

  const PrivacyLink = () => {
    return (
      <Link className="text-sm hover:text-gray-400" to={ROUTES.PRIVACY_POLICY}>
        Privacy
      </Link>
    );
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      {/* Main Content */}
      <div className="flex-grow">
        <div className="flex flex-col items-center justify-center bg-gray-100 p-4 relative">
          <img
            src="/assets/logo.png"
            alt="App Logo"
            className="absolute top-4 left-4 w-16 h-16"
          />
          <div className="flex flex-wrap gap-6 justify-center items-start max-w-5xl w-full">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
              <h1 className="text-xl font-bold text-center text-red-600 mb-4">
                <span className="text-primary">First 30 - Mobile App</span>{" "}
                <br />
                Delete User Account And Data In Mobile App
              </h1>
              {/* <p className="text-gray-700 mb-6 text-center">
                Follow the steps below to delete your account.
              </p> */}

              <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
                {/* <h2 className="text-xl font-semibold text-gray-800">
                  Delete Account And Data in Mobile App:
                </h2> */}
                <p className="text-gray-600 mt-4">
                  Users can delete their accounts directly from the First 30 -
                  Mobile App app by following these steps:
                </p>
                <ul className="list-disc list-inside text-gray-900 mt-2">
                  <li>
                    {" "}
                    <span className="text-gray-600">
                      Navigate to the Profile section in the app.
                    </span>
                  </li>
                  <li>
                    {" "}
                    <span className="text-gray-600">
                      Tap on the "Delete Account" option.
                    </span>
                  </li>
                  <li>
                    {" "}
                    <span className="text-gray-600">
                      Confirm the deletion to delete the account and data.
                    </span>
                  </li>
                </ul>
              </div>

              <div className="p-4 bg-gray-50 rounded-lg border border-gray-200 mt-4">
                <p className="text-gray-600">
                  {" "}
                  Once you confirm the deletion of your account, all your
                  account data will be removed from the First 30 - Mobile App
                  with in 90 days.
                </p>

                <p className="text-gray-600 mt-6">
                  For further assistance, please email us at:{" "}
                  <a
                    href="mailto:support@yourfirstthirtydays.com."
                    className="text-blue-600 underline"
                  >
                    support@yourfirstthirtydays.com
                  </a>
                </p>
              </div>

              {/* Steps to Delete Account in App */}
              {/* <div className="space-y-4">
   

    <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
      <h2 className="text-xl font-semibold text-gray-800">
        2. Navigate to the Profile Section
      </h2>
      <p className="text-gray-600">
        Go to the Profile section, which is located on the 5th tab in
        the app navigation bar.
      </p>
    </div>

    <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
      <h2 className="text-xl font-semibold text-gray-800">
        3. Select Delete Account
      </h2>
      <p className="text-gray-600">
        In the Profile section, tap on the "Delete Account" option.
      </p>
    </div>

    <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
      <h2 className="text-xl font-semibold text-gray-800">
        4. Confirm Deletion
      </h2>
      <p className="text-gray-600">
        A confirmation prompt will appear. Select
        <span className="font-semibold text-red-600"> Delete</span> to
        permanently delete your account or
        <span className="font-semibold text-green-600"> Keep</span> if
        you change your mind.
      </p>
    </div>

    <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
      <h2 className="text-xl font-semibold text-gray-800">
        5. Final Step
      </h2>
      <p className="text-gray-600">
        Once confirmed, your account will be permanently deleted, and
        you will lose access to all data associated with it.
      </p>
    </div>
  </div> */}
            </div>

            {/* Second Column */}
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
              <h1 className="text-xl font-bold text-center text-red-600 mb-4">
                <span className="text-primary">First 30 - Mobile App</span>{" "}
                <br />
                Delete User Account And Data for Uninstalled App
              </h1>
              {/* <p className="text-gray-700 mb-6 text-center">
                Follow the steps below to delete your account.
              </p> */}
              <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
                {/* <h2 className="text-xl font-semibold text-gray-800">
                  Delete Account And Data for Uninstalled App:
                </h2> */}
                {/* <p className="mt-2 text-gray-600">
                  {" "}
                  To request account deletion:
                </p> */}
                <p className="text-gray-600">
                  <br /> Please email us at{" "}
                  <a
                    href="mailto:support@yourfirstthirtydays.com."
                    className="text-blue-600 underline"
                  >
                    support@yourfirstthirtydays.com
                  </a>{" "}
                  using the email address registered with your First 30 - Mobile
                  App account.
                  <p className="mt-4 text-gray-600">
                    We will process your request to delete your account and user
                    data within 90 days.
                  </p>
                  {/* <br /> Once the deletion is complete, you will receive a
                  confirmation email. */}
                </p>
                <p className="text-gray-600 mt-6">
                  For further assistance, please email us at:{" "}
                  <a
                    href="mailto:support@yourfirstthirtydays.com."
                    className="text-blue-600 underline"
                  >
                    support@yourfirstthirtydays.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gray-900 text-white text-sm p-4 h-24">
        <div className="max-w-5xl mx-auto flex flex-col md:flex-row items-center justify-between">
          <div className="flex space-x-4">
            <p>© 2024 Your First 30 days</p>
            <TermLink />
            <PrivacyLink />
          </div>
          <div className="flex items-center gap-4 mt-4 md:mt-0">
            <label>Contact Us:</label>
            <p>support@yourfirstthirtydays.com</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default DeleteAccountInfoPage;
